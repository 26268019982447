function Card({answers, logo, title, handleAnswer}) {

    return (
        <div className="card">
            <img src={logo} alt="Логотип Производства" className="card__icon"/>
            <h3 className="title card__title">{title}</h3> 
                {
                    answers.map((answer) => (
                            <button 
                                className="button card__button" 
                                onClick={() => { handleAnswer(answer.codeAnswer)}}
                            >
                                {answer.title}
                            </button>
                    ))
                }
        </div>
    );
}

export default Card;