import cl from "./index.module.css"

import {Button, Col, Container, Row} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import words from "../../wordList.json";


type RenderTimeProps = {
    remainingTime: number;
    // Your other props here.
}


const RenderTime = ({ remainingTime }: RenderTimeProps) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(0);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);

    if (currentTime.current !== remainingTime) {
        isNewTimeFirstTick.current = true;
        prevTime.current = currentTime.current;
        currentTime.current = remainingTime;
    } else {
        isNewTimeFirstTick.current = false;
    }

    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
        setTimeout(() => {
            setOneLastRerender((val) => val + 1);
        }, 20);
    }

    const isTimeUp = isNewTimeFirstTick.current;

    // return (
    //     <div className="time-wrapper">
    //         <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
    //             {remainingTime}
    //         </div>
    //         {prevTime.current !== null && (
    //             <div
    //                 key={prevTime.current}
    //                 className={`time ${!isTimeUp ? "down" : ""}`}
    //             >
    //                 {prevTime.current}
    //             </div>
    //         )}
    //     </div>
    // );
    return (
        <div>
            {remainingTime}
            {prevTime.current}
        </div>
    );
};


function Chart() {

    const [line1, setLine1] = useState([{name:0, v:0, hh:10, ll: 2}])
    const [newValue, setNewValue] = useState(0)
    const [axixX, setAxixX] = useState(0);

    const [degrees, setDegrees] = useState(0)
    const [degreesDelta, setDegreesDelta] = useState(5)
    const [baseLine, setBaseLine] = useState(6)
    const [amplitude, setAmplitude] = useState(2)

    const [sign, setSign] = useState(1)

    const [data3, setData3] = useState([
        ...line1
    ])

    const [keyTimer, setKeyTimer] = useState(0);


    const MINUTE_MS = 100;

    // https://codesandbox.io/s/vigilant-jasper-gkhzys?file=/src/App.js:428-501
    useEffect(() => {
        const interval = setInterval(() => {
            addValueSin()
        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [axixX])


    const initSin = () => {
        setDegreesDelta(Math.random()*3 + 2)
        setAmplitude(Math.random()*3 + 2)
    }

    useEffect(() => {
        initSin();
        const interval = setInterval(() => {
            initSin()
        }, 10000);
        return () => clearInterval(interval);
    }, [])

    const addValue = () => {
        setAxixX(axixX + 1);
        let v = newValue +  (Math.random() * 0.5 *sign)
        if (v > 12) v = 12
        if (v < 0) v = 0
        setNewValue(v)

        if(line1.length > 100) setLine1([...line1.slice(1), {name:axixX, v:newValue, hh:10, ll: 2}])
        else setLine1([...line1, {name:axixX, v:newValue, hh:10, ll: 2}])

        setData3([...line1])
    }


    const addValueSin = () => {
        setAxixX(axixX + 1);
        let rndV = (Math.random() - 0.5)

        let radians = degrees * Math.PI/180;
        let v = Math.sin(radians) * amplitude + baseLine - rndV;

        // if(degrees > 259) setDegrees(0)
        // else setDegrees(degrees + 5);

        setDegrees(degrees + degreesDelta)

        setNewValue(v)

        if(line1.length > 200) setLine1([...line1.slice(1), {name:axixX, v:newValue, hh:10, ll: 2}])
        else setLine1([...line1, {name:axixX, v:newValue, hh:10, ll: 2}])

        setData3([...line1])
    }

    const changeSign = () => {
        // setSign(sign* (-1))
        console.log('======')
        console.log(sign)
        // sign.value = sign.value * (-1)
        setSign( sign*(-1))
        console.log(sign)
    }


    const restartTimer = () => {
        setKeyTimer(prevKey => prevKey + 1);
        initSin();
    }



    const renderCountdownTimew = () => {
        return (
            <CountdownCircleTimer
                key={keyTimer}
                isPlaying
                duration={10}
                onComplete={(n) => restartTimer()}
                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                colorsTime={[7, 5, 2, 0]}>
                {RenderTime}
            </CountdownCircleTimer>
        )
    }

    return (
           <Row className="align-items-center">
                <Col xs={2}>
                    <div>
                        <Button
                            onClick={restartTimer}
                        >
                            Init Sin
                        </Button>
                        {/*{renderCountdownTimew()}*/}
                        <div className={cl.debug}>amplitude: {Math.round(amplitude * 100) / 100}</div>
                        <div className={cl.debug}>degreesDelta: {Math.round(degreesDelta * 100) / 100}</div>
                    </div>
                    {/*<div>{sign}</div>*/}
                </Col>
                <Col xs={10}>
                    <div className={cl.chart}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={500}
                                height={300}
                                data={data3}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Line type="monotone" dataKey="v" stroke="#8884d8" activeDot={{r: 8}} strokeWidth={5}/>
                                <Line type="monotone" dataKey="hh" stroke="#FF0000"/>
                                <Line type="monotone" dataKey="ll" stroke="#FF0000"/>
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </Col>
            </Row>


    )
}

export default Chart
