import axios from "axios";


export default class Api {

    static ScenarioLoging(experimentId, actionId, actionValue, CorrectOrInСorrect) {
        const operatorId = localStorage.getItem('operatorId')

        let customConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        let raw = JSON.stringify({
            "experimentId": experimentId,
            "operatorId": operatorId,
            "scenarioId": 1,
            "scenarioLevel": 1,
            "timestamp": Date.now(),
            "actionId": actionId,
            "actionValue": actionValue,
            "CorrectOrInСorrect": CorrectOrInСorrect
        });

        console.log(raw);

        const apiUrl = 'http://62.113.110.150:5000/scenario-logging/add';
        axios.post(apiUrl, raw, customConfig).then(r => {
            console.log(r)
        });


    }

    static SummaryPost(experimentId, incorrectActionCount, correctActionCount) {
        const operatorId = localStorage.getItem('operatorId')

        let customConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        var raw = JSON.stringify({
            "experimentId": experimentId,
            "operatorId": operatorId,
            "scenarioId": 1,
            "scenarioLevel": 1,
            "incorrectActionCount": incorrectActionCount,
            "correctActionCount": correctActionCount,
            "completeTime": 169951318044,
            "timestampBegin": 1699513180458,
            "timestampEnd": Date.now()
        });

        console.log(raw);

        const apiUrl = 'http://62.113.110.150:5000/summary/add';
        axios.post(apiUrl, raw, customConfig).then(r => {
            console.log(r)
        });


    }

    static GraphLoging(experimentId, graphId, graphValue) {
        const operatorId = localStorage.getItem('operatorId')

        let customConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        // let raw = JSON.stringify({
        //     "experimentId": experimentId,
        //     "operatorId": operatorId,
        //     "scenarioId": 1,
        //     "scenarioLevel": 1,
        //     "timestamp": Date.now(),
        //     "actionId": actionId,
        //     "actionValue": actionValue,
        //     "CorrectOrInСorrect": CorrectOrInСorrect
        // });
        //

        let raw = JSON.stringify({
            "experimentId": experimentId,
            "operatorId": operatorId,
            "scenarioId": 1,
            "scenarioLevel": 1,
            "graphId": graphId,
            "graphValue": graphValue,
            "timestamp": Date.now(),
            "validValue": 0
        })


        console.log(raw);

        const apiUrl = 'http://62.113.110.150:5000//graph-logging/add';
        axios.post(apiUrl, raw, customConfig).then(r => {
            console.log(r)
        });


    }
}

