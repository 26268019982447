import cl from "./index.module.css"

import {Container, Row} from "react-bootstrap";
import ChartPid1 from "../../component/pid/chartPid1";
import ChartPid2 from "../../component/pid/chartPid2";
import ChartPid3 from "../../component/pid/chartPid3";
import ChartPid4 from "../../component/pid/chartPid4";


function Test4pid() {


    return (
        <Container>
                <h1>Тренды ПИД</h1>
                <ChartPid1/>
                <ChartPid2/>
                <ChartPid3/>
                <ChartPid4/>
        </Container>

    )
}

export default Test4pid
