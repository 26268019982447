import cl from "./index.module.css"

import {Button, Col, Container, Row} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import words from "../../../wordList.json";
import Api from "../../../helpers/api";


const RenderTime = (remainingTime) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(0);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);

    if (currentTime.current !== remainingTime) {
        isNewTimeFirstTick.current = true;
        prevTime.current = currentTime.current;
        currentTime.current = remainingTime;
    } else {
        isNewTimeFirstTick.current = false;
    }

    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
        setTimeout(() => {
            setOneLastRerender((val) => val + 1);
        }, 20);
    }

    const isTimeUp = isNewTimeFirstTick.current;

    // return (
    //     <div className="time-wrapper">
    //         <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
    //             {remainingTime}
    //         </div>
    //         {prevTime.current !== null && (
    //             <div
    //                 key={prevTime.current}
    //                 className={`time ${!isTimeUp ? "down" : ""}`}
    //             >
    //                 {prevTime.current}
    //             </div>
    //         )}
    //     </div>
    // );
    return (
        <div>
            {remainingTime}
            {prevTime.current}
        </div>
    );
};


function PID(p, i, d) {
    this.sampleTime = 100
    this.target = 0
    this.output = 0

    this.errorSum = 0
    this.lastInput = 0
    this.lastTime = Date.now() - this.sampleTime

    this.setTunings(p, i, d)
}

PID.prototype.setTunings = function(p, i, d) {
    var ratio = this.sampleTime / 1000

    this.p = p
    this.i = i * ratio
    this.d = d / ratio
}

PID.prototype.setSampleTime = function(sampleTime) {
    var ratio = sampleTime / this.sampleTime

    this.i *= ratio
    this.d /= ratio
    this.sampleTime = sampleTime
}

PID.prototype.setOutputLimits = function(min, max) {
    this.min = min
    this.max = max
}

PID.prototype.setTarget = function(value) {
    this.target = value
}

PID.prototype.compute = function(input) {
    var now = Date.now(),
        timeDiff = now - this.lastTime

    if (timeDiff >= this.sampleTime) {
        var error = this.target - input,
            inputDiff = input - this.lastInput

        this.errorSum = Math.max(this.min, Math.min(this.max, this.errorSum + (this.i * error)))
        this.output = Math.max(this.min, Math.min(this.max, (this.p * error) + this.errorSum - (this.d * inputDiff)))
        this.lastInput = input
        this.lastTime = now
    }

    return this.output
}

let pid = new PID(0.2, 0.5, 0.1)
pid.setSampleTime(100)
pid.setOutputLimits(0, 100)
pid.setTarget(50)
console.log("pid reset")

let pv = 50;
let mv = 0;
let sp = 50;

const  calcPV = () => {
    pv = pv - 3 + mv*0.2;
}

function ChartPid1() {

    const [line1, setLine1] = useState([{name:0, v:0, hh:80, ll: 20}])
    const [newValue, setNewValue] = useState(0)
    const [axixX, setAxixX] = useState(0);

    const [degrees, setDegrees] = useState(0)
    const [degreesDelta, setDegreesDelta] = useState(5)
    const [baseLine, setBaseLine] = useState(6)
    const [amplitude, setAmplitude] = useState(2)

    const [spDisplay, setSpDisplay] = useState(pv)

    const [sign, setSign] = useState(1)

    const [data3, setData3] = useState([
        ...line1
    ])

    const [keyTimer, setKeyTimer] = useState(0);


    const MINUTE_MS = 100;

    // https://codesandbox.io/s/vigilant-jasper-gkhzys?file=/src/App.js:428-501
    useEffect(() => {
        const interval = setInterval(() => {
            //addValueSin()
            addValuePid()
        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [axixX])


    const initSin = () => {
        setDegreesDelta(Math.random()*3 + 2)
        setAmplitude(Math.random()*3 + 2)
    }

    const initSp = () => {
        sp = (sp + Math.random()*40 - 20);
        if (sp > 95) sp = 95;
        if (sp < 5 ) sp = 5;

        setSpDisplay(sp);

        pid.setTarget(sp);
    }

    useEffect(() => {
        initSin();
        const interval = setInterval(() => {
            initSp();
        }, 30000);
        return () => clearInterval(interval);
    }, [])

    const addValue = () => {
        setAxixX(axixX + 1);
        let v = newValue +  (Math.random() * 0.5 *sign)
        if (v > 12) v = 12
        if (v < 0) v = 0
        setNewValue(v)

        if(line1.length > 100) setLine1([...line1.slice(1), {name:axixX, v:newValue, hh:80, ll: 20}])
        else setLine1([...line1, {name:axixX, v:newValue, hh:80, ll: 20}])

        setData3([...line1])
    }


    const addValuePid = () => {
        setAxixX(axixX + 1);

        // let v = pid.compute(50 + (Math.random() * 10));
        mv = pid.compute(pv);
        calcPV();
        setNewValue(pv);

        if(line1.length > 200) setLine1([...line1.slice(1), {name:axixX, v:newValue, hh:80, ll: 20}])
        else setLine1([...line1, {name:axixX, v:newValue, hh:80, ll: 20}])

        setData3([...line1])

        Api.GraphLoging(4, 1, pv)
    }

    const addValueSin = () => {
        setAxixX(axixX + 1);
        let rndV = (Math.random() - 0.5)

        let radians = degrees * Math.PI/180;
        let v = Math.sin(radians) * amplitude + baseLine - rndV;

        // if(degrees > 259) setDegrees(0)
        // else setDegrees(degrees + 5);

        setDegrees(degrees + degreesDelta)

        setNewValue(v)

        if(line1.length > 200) setLine1([...line1.slice(1), {name:axixX, v:newValue, hh:90, ll: 10}])
        else setLine1([...line1, {name:axixX, v:newValue, hh:90, ll: 10}])

        setData3([...line1])
    }

    const changeSign = () => {
        // setSign(sign* (-1))
        console.log('======')
        console.log(sign)
        // sign.value = sign.value * (-1)
        setSign( sign*(-1))
        console.log(sign)
    }


    const restartTimer = () => {
        setKeyTimer(prevKey => prevKey + 1);
        initSin();
        initSp();
    }

    const renderCountdownTimew = () => {
        return (
            <CountdownCircleTimer
                key={keyTimer}
                isPlaying
                duration={10}
                onComplete={(n) => restartTimer()}
                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                colorsTime={[7, 5, 2, 0]}>
                {RenderTime}
            </CountdownCircleTimer>
        )
    }


    function modSpUp() {
        console.log(sp);
        sp = sp + 5;
        pid.setTarget(sp);
        setSpDisplay(sp);
        console.log(sp)
    }
    function modSpDown() {
        console.log(sp);
        sp = sp - 5;
        pid.setTarget(sp);
        setSpDisplay(sp);
        console.log(sp)
    }

    return (
           <Row className="align-items-center">
                <Col xs={1}>
                    <div>
                        {/*<div>*/}
                        {/*    <Button*/}
                        {/*        onClick={restartTimer}*/}
                        {/*    >*/}
                        {/*        Init Sin*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                        <div>
                            <Button
                                onClick={modSpUp}
                                className={cl.btn}
                            >
                                ↑
                            </Button>
                        </div>
                        <div>
                            <Button
                                onClick={modSpDown}
                                className={cl.btn}
                            >
                                ↓
                            </Button>
                        </div>

                        {/*{renderCountdownTimew()}*/}
                        {/*<div className={cl.debug}>amplitude: {Math.round(amplitude * 100) / 100}</div>*/}
                        {/*<div className={cl.debug}>degreesDelta: {Math.round(degreesDelta * 100) / 100}</div>*/}
                        {/*<div className={cl.debug}>sp: {Math.round(sp * 100) / 100}</div>*/}
                        {/*<div className={cl.debug}>pv: {Math.round(pv * 100) / 100}</div>*/}
                        {/*<div className={cl.debug}>spDisplay: {Math.round(spDisplay * 100) / 100}</div>*/}
                    </div>
                    {/*<div>{sign}</div>*/}
                </Col>
                <Col xs={11}>
                    <div className={cl.chart}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={500}
                                height={300}
                                data={data3}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Line type="monotone" dataKey="v" stroke="#8884d8" activeDot={{r: 8}} strokeWidth={5}/>
                                <Line type="monotone" dataKey="hh" stroke="#FF0000"/>
                                <Line type="monotone" dataKey="ll" stroke="#FF0000"/>
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </Col>
            </Row>


    )
}

export default ChartPid1
