import cl from "./index.module.css"

import {useCallback, useEffect, useRef, useState} from "react";

type Test1Props = {
    back: () => void;

}


function TestPage({back}: Test1Props) {

    return (
        <div className='page'>
            Test page
            <div className="page">
                Test page3

            </div>
        </div>
    )
}

export default TestPage
