import Countdown from "react-countdown";

function Task({ description, styleTaskValue, testStarted, testDone, index, tag, seconds, renderer, handleStart}) {
    return (
        <section className="task">
            <div className="task__container">
                <div>
                    <h2 className="title">Задание:</h2>
                    <p className="description">{description}</p>
                </div>
                <div className="task__timer">
                    { testStarted && !testDone 
                        ? 
                            <Countdown
                                key={index}
                                date={Date.now() + seconds * 1000}
                                renderer={renderer}
                            /> 
                        : 
                            0
                    }
                </div>
            </div>
            <div className={styleTaskValue}>
                { testDone 
                    ?
                        <div>Тест завершен</div>
                    : 
                        testStarted ? tag
                                    :   <button className="button task__button-start" onClick={handleStart}>
                                            Начать
                                        </button>
                }
            </div>
        </section>
    )    
}

export default Task;