import icon_plant1 from '../../images/plant-1.png'
import icon_plant2 from '../../images/plant-2.png'
import icon_plant3 from '../../images/plant-3.png'

import tags from "./tagsListLong.json";
import {useState} from "react";
import Api from "../../helpers/api";
import Header from "../../component/Header";
import Task from "../../component/Task"
import Card from "../../component/Card";
import Result from "../../component/Result";


function shuffle<T>(array: T[]) {
    let myClonedArray: T[] = [];
    array.forEach(val => myClonedArray.push(Object.assign({}, val)));

    let currentIndex = myClonedArray.length;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

        // Pick a remaining element...
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [myClonedArray[currentIndex], myClonedArray[randomIndex]] = [
            myClonedArray[randomIndex], myClonedArray[currentIndex]];
    }

    return myClonedArray;
}


type Test6Props = {
    back: () => void;

}

function Test6({back}: Test6Props) {

    const [answersPlant1, setAnswersPlant1] = useState([
        {title: 'Датчик давления', codeAnswer: 101}, 
        {title: 'Регулятор давления', codeAnswer: 102},
        {title: 'Датчик расхода', codeAnswer: 103}, 
        {title: 'Регулятор расхода', codeAnswer: 104},
        {title: 'Датчик уровня', codeAnswer: 105}, 
        {title: 'Регулятор уровня', codeAnswer: 106},
        {title: 'Датчик температуры', codeAnswer: 107}, 
        {title: 'Регулятор температуры', codeAnswer: 108},
    ]);

    const [answersPlant2, setAnswersPlant2] = useState([
        {title: 'Датчик давления', codeAnswer: 201}, 
        {title: 'Регулятор давления', codeAnswer: 202},
        {title: 'Датчик расхода', codeAnswer: 203}, 
        {title: 'Регулятор расхода', codeAnswer: 204},
        {title: 'Датчик уровня', codeAnswer: 205}, 
        {title: 'Регулятор уровня', codeAnswer: 206},
        {title: 'Датчик температуры', codeAnswer: 207}, 
        {title: 'Регулятор температуры', codeAnswer: 208},
    ]);

    const [answersPlant3, setAnswersPlant3] = useState([
        {title: 'Датчик давления', codeAnswer: 301}, 
        {title: 'Регулятор давления', codeAnswer: 302},
        {title: 'Датчик расхода', codeAnswer: 303}, 
        {title: 'Регулятор расхода', codeAnswer: 304},
        {title: 'Датчик уровня', codeAnswer: 305}, 
        {title: 'Регулятор уровня', codeAnswer: 306},
        {title: 'Датчик температуры', codeAnswer: 307}, 
        {title: 'Регулятор температуры', codeAnswer: 308},
    ]);

    const [tag, setTag] = useState('--');
    const [code, setCode] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const [correct, setCorrect] = useState(0);
    const [errors, setErrors] = useState(0);

    const [index, setIndex] = useState(0);
    const [nextDisable, setNextDisable] = useState(false);
    const [testStarted, setTestStarted] = useState(false);
    const [testDone, setTestDone] = useState(false);

    const[classes, setClasses] = useState("task__container_value" + ' ' + "task__container_value_test2");


    const handleAnswer = (codeAnswer: number) => {
        if(testDone || !testStarted) return;

        if (code == codeAnswer) {
            setCorrect(correct + 1);
            setClasses("task__container_value" + ' ' + "task__tag_correct" + ' ' + "task__container_value_test2");
            nextTag();
            Api.ScenarioLoging(2, 1, codeAnswer.toString(), 1);
        }
        else {
            setErrors(errors + 1);
            setClasses("task__container_value" + ' ' + "task__tag_error" + ' ' + "task__container_value_test2");
            Api.ScenarioLoging(2, 1, codeAnswer.toString(), 0);
        }

        setTimeout(()=>setClasses("task__container_value" + ' ' + "task__container_value_test2"), 2000);
    }

    const nextTag = () => {
        setAnswersPlant1(shuffle(answersPlant1));
        setAnswersPlant2(shuffle(answersPlant2));
        setAnswersPlant3(shuffle(answersPlant3));

        if((index+1) >= tags.length) {
            setNextDisable(true);
            setTestDone(true);

            Api.SummaryPost(2, errors, correct+1)


            return;
        }
        setIndex(index + 1);
        // @ts-ignore
        setCode(tags[index + 1].code);
        setTag(tags[index + 1].tag);
        setSeconds(tags[index + 1].time);
    }

    const timerEnd = () => {
        nextTag();
    }

    function handleStart(){
        setTestStarted(true);
        // @ts-ignore
        setCode(tags[index].code);
        setTag(tags[index].tag);
        setSeconds(tags[index].time);
    }


    const Completionist = () => <span>----</span>;

// @ts-ignore
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            timerEnd();
            return <Completionist />;
        } else {
            // Render a countdown
            return <span>{minutes*60 + seconds}</span>;
        }
    };




    return (
        <div className="page">
            <Header
                onBack={back}
                title={'Тест 2. Последовательность тегов'}
            ></Header>
            <Task
                description={'Необходимо сопоставить отображаемый в задании тег соответствующему оборудованию на производстве за отведенное время.'}
                styleTaskValue={classes}
                testStarted={testStarted}
                testDone={testDone}
                index={index}
                tag={tag}
                seconds={seconds}
                renderer={renderer}
                handleStart={handleStart}
            ></Task>
            <section className="cards">
                <ul className="cards__list">
                    <li>
                        <Card
                            answers={answersPlant1}
                            logo={icon_plant1}
                            title={"Производство 1"}
                            handleAnswer={handleAnswer}
                        ></Card>
                    </li>
                    <li>
                        <Card
                            answers={answersPlant2}
                            logo={icon_plant2}
                            title={"Производство 2"}
                            handleAnswer={handleAnswer}
                        ></Card>
                    </li>
                    <li>
                        <Card
                        
                            answers={answersPlant3}
                            logo={icon_plant3}
                            title={"Производство 3"}
                            handleAnswer={handleAnswer}
                        ></Card>
                    </li>
                </ul>
            </section>
            <Result 
                correctValue={correct}
                errorValue={errors}
            ></Result>
        </div>
        
    )
}

export default Test6
