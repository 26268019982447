import icon_plant1 from '../../images/plant-1.png'
import icon_plant2 from '../../images/plant-2.png'
import icon_plant3 from '../../images/plant-3.png'

import {useState} from "react";

import tags from "./messagesList.json";

import Header from "../../component/Header";
import Task from "../../component/Task"
import Card from "../../component/Card";
import Result from "../../component/Result";
import Api from '../../helpers/api';


type Test3Props = {
    back: () => void;
}

function Test3({back}: Test3Props) {


    const [tag1, setTag1] = useState(tags[0].tag1);
    const [tag2, setTag2] = useState(tags[0].tag2);
    const [tag3, setTag3] = useState(tags[0].tag3);
    const [tag4, setTag4] = useState(tags[0].tag4);
    const [tag5, setTag5] = useState(tags[0].tag5);
    const [tag6, setTag6] = useState(tags[0].tag6);
    const [tag7, setTag7] = useState(tags[0].tag7);
    const [tag8, setTag8] = useState(tags[0].tag8);
    const [tag9, setTag9] = useState(tags[0].tag9);
    const [tagCode, setTagCode] = useState(tags[0].tagCode);
    const [tagCorrect, setTagCorrect] = useState(tags[0].tagCorrect);
    const [text, setText] = useState(tags[0].text);
    const [seconds, setSeconds] = useState(tags[0].time);
    const [correct, setCorrect] = useState(0);
    const [errors, setErrors] = useState(0);

    const [index, setIndex] = useState(0);
    const [nextDisable, setNextDisable] = useState(false);

    const [answersPlant1, setAnswersPlant1] = useState([
        {title: tag1, codeAnswer: 1},
        {title: tag2, codeAnswer: 2},
        {title: tag3, codeAnswer: 3},
    ]);

    const [answersPlant2, setAnswersPlant2] = useState([
        {title: tag4, codeAnswer: 4},
        {title: tag5, codeAnswer: 5},
        {title: tag6, codeAnswer: 6},
    ]);

    const [answersPlant3, setAnswersPlant3] = useState([
        {title: tag7, codeAnswer: 7},
        {title: tag8, codeAnswer: 8},
        {title: tag9, codeAnswer: 9},
    ]);

    const [testStarted, setTestStarted] = useState(false);
    const [testDone, setTestDone] = useState(false);
    const[classes, setClasses] = useState("task__container_value")

    const answer = (message: number) => {
        if (tagCode == message) {
            setCorrect(correct + 1);
            nextTag();
        }
        else setErrors(errors + 1);
        console.log(message);

    }

    const nextTag = () => {
        if((index+1) >= tags.length) {
            setNextDisable(true);
            return;
        }
        setIndex(index + 1);
        setTag1(tags[index + 1].tag1);
        setTag2(tags[index + 1].tag2);
        setTag3(tags[index + 1].tag3);
        setTag4(tags[index + 1].tag4);
        setTag5(tags[index + 1].tag5);
        setTag6(tags[index + 1].tag6);
        setTag7(tags[index + 1].tag7);
        setTag8(tags[index + 1].tag8);
        setTag9(tags[index + 1].tag9);
        setText(tags[index + 1].text);
        setTagCode(tags[index + 1].tagCode);
        setTagCorrect(tags[index + 1].tagCorrect);
        setSeconds(tags[index + 1].time);


        setAnswersPlant1([
            {title: tags[index + 1].tag1, codeAnswer: 1},
            {title: tags[index + 1].tag2, codeAnswer: 2},
            {title: tags[index + 1].tag3, codeAnswer: 3},
        ])

        setAnswersPlant2([
            {title: tags[index + 1].tag4, codeAnswer: 4},
            {title: tags[index + 1].tag5, codeAnswer: 5},
            {title: tags[index + 1].tag6, codeAnswer: 6},
        ])

        setAnswersPlant3([
            {title: tags[index + 1].tag7, codeAnswer: 7},
            {title: tags[index + 1].tag8, codeAnswer: 8},
            {title: tags[index + 1].tag9, codeAnswer: 9},
        ])
    }

    function handleStart(){
        setTestStarted(true);
        setClasses("task__container_value" + ' ' + "task__container_value_test3")
        console.log(testStarted);
        
        // @ts-ignore
        // setCode(tags[index].code);
        // setTag(tags[index].tag);
        // setSeconds(tags[index].seconds);
    }

    const handleAnswer = (codeAnswer: number) => {
        if(testDone || !testStarted) return;

        if (tagCode == codeAnswer) {
            setCorrect(correct + 1);
            setClasses("task__container_value" + ' ' + "task__tag_correct" + ' ' + "task__container_value_test3");
            nextTag();
            Api.ScenarioLoging(3, 1, codeAnswer.toString(), 1);

        }
        else {
            setErrors(errors + 1);
            setClasses("task__container_value" + ' ' + "task__tag_error" + ' ' + "task__container_value_test3");
           Api.ScenarioLoging(3, 1, codeAnswer.toString(), 0);
        }

        setTimeout(()=>setClasses("task__container_value" + ' ' + "task__container_value_test3"), 2000);
    }

    const Completionist = () => <span>----</span>;

    // @ts-ignore
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            timerEnd();
            setClasses("task__container_value" + ' ' + "task__container_value_test3");
            return <Completionist />;
        } else {
            // Render a countdown
            return <span>{minutes*60 + seconds}</span>;
        }
    };

    const timerEnd = () => {
        nextTag();
    }




    return (
        <div className="page">
            <Header 
                onBack={back}
                title={'Тест 3.  Последовательность сообщений'}    
            ></Header>
            <Task
                description={'Необходимо сопоставить отображаемое в задании сообщение соответствующему тегу оборудования на производстве за отведенное время. ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ' + 'Обозначения: HH - верхний аварийный уровень, H - верхний предупредительный уровень, L - нижний предупредительный уровень, LL - нижний аварийный уровень.ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ' + 'Номер технологического устройства на производстве состоит из 4 цифр, где первая цифра означает номер производства, последующие - номер устройства. ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤПример: насос H-2548: Насос №548 на Производстве 2. '}
                styleTaskValue={classes}
                testStarted={testStarted}
                testDone={testDone}
                index={index}
                tag={text}
                seconds={seconds*2}
                renderer={renderer}
                handleStart={handleStart}
            ></Task>
            <section className="cards">
                <ul className="cards__list">
                    <li>
                        <Card
                            answers={answersPlant1}
                            logo={icon_plant1}
                            title={"Производство 1"}
                            handleAnswer={handleAnswer}
                        ></Card>
                    </li>
                    <li>
                        <Card
                            answers={answersPlant2}
                            logo={icon_plant2}
                            title={"Производство 2"}
                            handleAnswer={handleAnswer}
                        ></Card>
                    </li>
                    <li>
                        <Card
                        
                            answers={answersPlant3}
                            logo={icon_plant3}
                            title={"Производство 3"}
                            handleAnswer={handleAnswer}
                        ></Card>
                    </li>
                </ul>
            </section>
            <Result 
                correctValue={correct}
                errorValue={errors}
            ></Result>
        </div>
    )
}

export default Test3
