function Result({ correctValue, errorValue }) {
    return (
        <footer className="result">
            <h2 className="title">Статистика:</h2>
            <p className="description">Количество правильных ответов: {correctValue}</p>
            <p className="description">Количество ошибочных ответов: {errorValue}</p>
        </footer>
    )
}

export default Result;