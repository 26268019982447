import cl from "./index.module.css"

import Chart from "../../component/chart";
import {Container, Row} from "react-bootstrap";


function Test4() {


    return (
        <Container>
                <h1>Тренды</h1>
                <Chart/>
                <Chart/>
                <Chart/>
                <Chart/>
        </Container>

    )
}

export default Test4
