import {useState, useEffect} from 'react';
import {Container, Stack, Button, Toast, Row, Col, Form} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Test1 from "./pages/test1";
import Test2 from "./pages/test2";
import Test4 from "./pages/test4";
import Test4pid from "./pages/test4pid";
import './index.css';
import axios from 'axios';
import Test3 from "./pages/test3";
import Test5 from './pages/test5';
import Test6 from "./pages/test6";
import TestPage from "./pages/testPage";

function Test4a() {
    return null;
}

function App() {

    const [username, setUsername] = useState('');
    const [isLogin, setIsLogin] = useState(false);

    const [testNum, setTestNum] = useState(0);
    const [results, setResults] = useState([]);



    useEffect(() => {
        const apiUrl = 'http://localhost:5000/user/result'
        // axios.get(apiUrl).then((resp) => {
        //     setResults(resp.data);
        //     console.log(resp.data);
        // });

    },[])


    useEffect(() => {
        // @ts-ignore
        setUsername(localStorage.getItem('username'));
    },[])


    const storageUsername = () => {
        console.log(username);
        localStorage.setItem('username',username);
    }

    const onFormSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        const apiUrl = 'http://62.113.110.150:5000/operator/login/' + username;
        axios.post(apiUrl).then((resp) => {
            let data = resp.data;
            if (typeof data['operatorId'] !== "undefined") {
                console.log(data['operatorId']);
                localStorage.setItem('username', data['name']);
                localStorage.setItem('operatorId', data['operatorId']);
                setIsLogin(true);
            }
            console.log(data);
        });
        localStorage.setItem('username',username);
        // send state to server with e.g. `window.fetch`
    }

    const loginForm = () => {
        return(
            <div className={"background"}>
                <div className={"login_form"}>
                    <Form onSubmit={onFormSubmit}>
                        <Form.Group className="mb-3" controlId="formUserName">
                            <Form.Label>Имя пользователя</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Введите имя пользователя"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Ввести
                        </Button>
                    </Form>
                </div>
            </div>
        )
    }


    const drawSelect = () => {
        return(
            <div className={"background"}>
                <Col>
                    <Row>
                        <Col xs={9}/>
                        <Col xs={3}>
                            <div className={"form"}>
                                <Button onClick={(_) => {setIsLogin(false)}}>
                                    Logout
                                </Button>
                                {username}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className='flex gap-3 text-3xl lg:text-6xl font-bold uppercase text row-item text-white'>
                            Выберите тест
                        </div>
                    </Row>
                    <Row>
                        <div className={"row-item"}>
                            <Button
                                className={"btn-lg  btn-menu"}
                                onClick={() => setTestNum(1)}>
                                Тест 1. Последовательность символов
                            </Button>
                        </div>
                    </Row>
                    <Row>
                        <div className={"row-item"}>
                            <Button
                                className={"btn-lg  btn-menu"}
                                onClick={() => setTestNum(2)}>
                                Тест 2. Последовательность тегов
                            </Button>
                        </div>
                    </Row>
                    <Row>
                        <div className={"row-item"}>
                            <Button
                                className={"btn-lg btn-menu"}
                                onClick={() => setTestNum(3)}>
                                Тест 3. Текстовые сообщения
                            </Button>
                        </div>
                    </Row>
                    <Row>
                        <div className={"row-item"}>
                            <Button
                                className={"btn-lg btn-menu"}
                                onClick={() => setTestNum(4)}>
                                Тест 4. Графическая информация
                            </Button>
                        </div>
                    </Row>
                    <Row>
                        <div className={"row-item"}>
                            <Button
                                className={"btn-lg btn-menu"}
                                onClick={() => setTestNum(5)}>
                                Тест 4a. Графическая информация. ПИД
                            </Button>
                        </div>
                    </Row>
                    <Row>
                        <div className={"row-item"}>
                            <Button
                                className={"btn-lg btn-menu"}
                                onClick={() => setTestNum(10)}>
                                Тест Страница
                            </Button>
                        </div>
                    </Row>
                    {/*<Row>*/}
                    {/*    <div className={"row-item"}>*/}
                    {/*        <Button*/}
                    {/*            className={"btn-lg btn-menu"}*/}
                    {/*            onClick={() => setTestNum(6)}>*/}
                    {/*            Тест 6. Последовательность тегов*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}
                    {/*</Row>*/}
                    <Row>
                        {results.map((result, i) => (
                            <div
                                key={i}
                                className={"flex gap-3 text-3xl lg:text-6xl font-bold uppercase text row-item text-white"}>
                                {result["user_id"]} : {result["errors"]}
                            </div>)
                        )}
                    </Row>
                </Col>
            </div>
        )
    }

    const inProgress = () => {
      return (
          <div>
              В разработке
              <Button onClick={() => setTestNum(0)}>
                  Вернуться к выбору
              </Button>
          </div>
      )
    }

    function back() {
        setTestNum(0);
    }

    const drawPage = () => {
        if (testNum == 0) return drawSelect();
        if (testNum == 1) return (<Test1 back={back}/>);
        if (testNum == 2) return (<Test6 back={back}/>);
        if (testNum == 3) return (<Test3 back={back}/>);
        if (testNum == 4) return (<Test4/>);
        if (testNum == 5) return (<Test4pid/>);
        if (testNum == 6) return (<Test6 back={back}/>);
        if (testNum == 10) return (<TestPage back={back}/>);
    }

    return (
        <Container>
            <Row>
                {isLogin ? drawPage() : loginForm()}
            </Row>
        </Container>
    )
}

export default App
