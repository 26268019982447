import cl from "./index.module.css"

import {useCallback, useEffect, useRef, useState} from "react";
import words from "./wordList.json";
import {toast, Toaster} from "react-hot-toast";
import {Col, Container, Row} from "react-bootstrap";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import HangmanWord from "../../component/HangmanWord";
import Keyboard from "../../component/Keyboard";
import KeyboardDigit from "../../component/KeyboardDigit";
import axios from "axios";
import Api from "../../helpers/api";
import Header from "../../component/Header";
import Task from "../../component/Task";
import Result from "../../component/Result";
import tags from "../test3/messagesList.json";
import Countdown from "react-countdown";



type RenderTimeProps = {
    remainingTime: number;
    // Your other props here.
}
//
// const renderTime = ({ remainingTime }: RenderTimeProps) => {
//     if (remainingTime === 0) {
//         return <div className="timer">Too lale...</div>;
//     }
//
//     return (
//         <div className="timer">
//             <div className="text-timer">Remaining</div>
//             <div className="value">{remainingTime}</div>
//             <div className="text-timer">seconds</div>
//         </div>
//     );
// };

const RenderTime = ({ remainingTime }: RenderTimeProps) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(0);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);

    if (currentTime.current !== remainingTime) {
        isNewTimeFirstTick.current = true;
        prevTime.current = currentTime.current;
        currentTime.current = remainingTime;
    } else {
        isNewTimeFirstTick.current = false;
    }

    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
        setTimeout(() => {
            setOneLastRerender((val) => val + 1);
        }, 20);
    }

    const isTimeUp = isNewTimeFirstTick.current;

    return (
        // <div className="time-wrapper">
        <div className="timer__font">
            <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
                {remainingTime}
            </div>
            {/*{prevTime.current !== null && (*/}
            {/*    <div*/}
            {/*        key={prevTime.current}*/}
            {/*        className={`time ${!isTimeUp ? "down" : ""}`}*/}
            {/*    >*/}
            {/*        {prevTime.current}*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};

const RenderTimeFull = ({ remainingTime }: RenderTimeProps) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(0);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);

    if (currentTime.current !== remainingTime) {
        isNewTimeFirstTick.current = true;
        prevTime.current = currentTime.current;
        currentTime.current = remainingTime;
    } else {
        isNewTimeFirstTick.current = false;
    }

    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
        setTimeout(() => {
            setOneLastRerender((val) => val + 1);
        }, 20);
    }

    const isTimeUp = isNewTimeFirstTick.current;

    return (
        // <div className="time-wrapper">
        <div className="timer__font">
            <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
                {remainingTime}
            </div>
            {/*{prevTime.current !== null && (*/}
            {/*    <div*/}
            {/*        key={prevTime.current}*/}
            {/*        className={`time ${!isTimeUp ? "down" : ""}`}*/}
            {/*    >*/}
            {/*        {prevTime.current}*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};


type Test1Props = {
    back: () => void;

}

function Test1({back}: Test1Props) {
    const [wordToGuess, setWordToGuess] = useState(() => {
        return words[Math.floor(Math.random() * words.length)]
    });

    const [guessLetters, setGuessLetters] = useState<string[]>([]);

    const [wrong, setWrong] = useState(0);

    const [keyTimerFull, setKeyTimerFull] = useState(0);
    const [keyTimer, setKeyTimer] = useState(0);

    const [stoppedGame, setStoppedGame] = useState(false);

    const[classes, setClasses] = useState("task__container_value")
    const [index333, setIndex333] = useState(0);


    // take and filter the letters we guess
    const incorrectLetters = guessLetters.filter(
        letter => !wordToGuess.includes(letter)
    )

    let stoppedTimer = false;

    const isLoser = incorrectLetters.length >= 6;
    const isWinner = wordToGuess
        .split('')
        .every(letter => guessLetters.includes(letter));




    const addGuessLetter = useCallback((letter: string) => {
        console.log(letter);
        let correctOrInСorrect = 1;

        if (!wordToGuess.includes(letter)) {
            setWrong(wrong + 1);
            correctOrInСorrect = 0;
        }
        if (guessLetters.includes(letter) || isLoser || isWinner) {
            return
        } else {
            setGuessLetters(currentLetters => [...currentLetters, letter])
        }

        let customConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        let raw = JSON.stringify({
            "experimentId": 1,
            "operatorId": 1,
            "scenarioId": 1,
            "scenarioLevel": 1,
            "timestamp": "1700659250",
            "actionId": 1,
            "actionValue": letter,
            "CorrectOrInСorrect": correctOrInСorrect
        });

        const apiUrl = 'http://62.113.110.150:5000/scenario-logging/add';
        // axios.post(apiUrl, raw, customConfig);

        Api.ScenarioLoging(1, 1, letter, correctOrInСorrect);

    }, [guessLetters, isLoser, isWinner])


    // const stopTimer = useCallback(() => {
    //         console.log("Stop timer!!!");
    //         stoppedTimer = true;
    //         setWordToGuess(words[Math.floor(Math.random() * words.length)]);
    //         setGuessLetters([]);
    //         toast.error('Timer is down!', {
    //             duration: 5000
    //         });
    //     }
    //     , [])


    // keyboard event handler
    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            const key = e.key

            if (!key.match(/^[a-z]$/)) {
                return
            } else {
                e.preventDefault();
                addGuessLetter(key);
            }
        }

        document.addEventListener('keypress', handler)

        return () => {
            document.removeEventListener("keypress", handler)
        }
    }, [guessLetters]);

    useEffect(() => {
        if (isWinner) {
            toast('Congratulations, you won!', {
                icon: '👏',
                duration: 5000
            });
        }
    }, [isWinner]);

    useEffect(() => {
        if (isLoser) {
            toast.error('You lost, please refresh the page!', {
                duration: 5000
            })
        }
    }, [isLoser, wordToGuess]);


    const restartTimer = () => {
        if(stoppedGame) return;
        setKeyTimer(prevKey => prevKey + 1);
        setWordToGuess(words[Math.floor(Math.random() * words.length)]);
        setGuessLetters([]);
    }

    const stopGame = () => {
        setStoppedGame(true);
        toast('Test is done!', {
            icon: '👏',
            duration: 5000
        });
    }

    // @ts-ignore
    function TaskWord({ description, styleTaskValue, testStarted, testDone, index, tag, seconds, renderer, handleStart}) {
        return (
            <section className="task">
                <div className="task__container">
                    <div>
                        <h2 className="title">Задание:</h2>
                        <p className="description">{description}</p>
                    </div>
                    <div className="task__timer">
                        { testStarted && !testDone
                            ?
                            <CountdownCircleTimer
                                key={keyTimer}
                                isPlaying
                                duration={30}
                                onComplete={(n) => restartTimer()}
                                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                                colorsTime={[7, 5, 2, 0]}>
                                {RenderTime}
                            </CountdownCircleTimer>                            :
                            0
                        }
                    </div>
                </div>
                <div className={styleTaskValue}>
                    { testDone
                        ?
                        <div>Тест завершен</div>
                        :
                        testStarted ? tag
                            :   <button className="button task__button-start" onClick={handleStart}>
                                Начать
                            </button>
                    }
                </div>
            </section>
        )
    }


    const renderTimers = () => {
        return (
            <Row className={"header"}>
                <Col>
                    <CountdownCircleTimer
                        key={keyTimerFull}
                        isPlaying
                        duration={60*30}
                        colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                        colorsTime={[7, 5, 2, 0]}
                        size={0}
                        onComplete={(_) => stopGame()}>
                        {RenderTime}
                    </CountdownCircleTimer>
                </Col>
                {/*<Col>*/}
                {/*    <div className="timer-wrapper">*/}
                {/*        <CountdownCircleTimer*/}
                {/*            key={keyTimer}*/}
                {/*            isPlaying*/}
                {/*            duration={30}*/}
                {/*            onComplete={(n) => restartTimer()}*/}
                {/*            colors={['#004777', '#F7B801', '#A30000', '#A30000']}*/}
                {/*            colorsTime={[7, 5, 2, 0]}>*/}
                {/*            {RenderTime}*/}
                {/*        </CountdownCircleTimer>*/}
                {/*    </div>*/}
                {/*</Col>*/}
            </Row>
        );
    }

    const renderEnd = () => {
        return (
            <Row className={"header"}>
                <div className='flex gap-3 text-3xl lg:text-6xl font-bold uppercase text timer-wrapper'>
                    ВсЁ
                </div>
            </Row>
        )
    }

    const Completionist = () => <span>----</span>;

    // @ts-ignore
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            timerEnd();
            setClasses("task__container_value" + ' ' + "task__container_value_test3");
            return <Completionist />;
        } else {
            // Render a countdown
            return <span>{minutes*60 + seconds}</span>;
        }
    };

    const timerEnd = () => {
        nextTag();
        console.log("nextTag");



    }

    const nextTag = () => {
        // if ((index + 1) >= tags.length) {
        //     setNextDisable(true);
        //     return;
        // }
        setIndex333(index333 + 1);

        setWordToGuess(words[Math.floor(Math.random() * words.length)]);
        setGuessLetters([]);
    }

    let description='Необходимо повторить отображаемую в задании последовательность за отведенное время. При досрочном выполнении задания необходимо дождаться окончания отведенного времени.';
    let styleTaskValue="task__container_value task__container_value_test1"; //{classes}
    let testStarted=true; //{testStarted}
    let testDone=false;//{testDone}
    // let index=index333
    //     tag={wordToGuess}
    //     seconds={10}//{seconds}
    //     renderer={renderer}
    //     handleStart={null}


    return (
        <div className="page">
            <Header
                onBack={back}
                title={'Тест 1. Последовательность символов'}
            ></Header>
            {/*<TaskWord*/}
            {/*    description={'Необходимо повторить отображаемую в задании последовательность за отведенное время. При досрочном выполнении задания необходимо дождаться окончания отведенного времени.'}*/}
            {/*    styleTaskValue={"task__container_value task__container_value_test1"} //{classes}*/}
            {/*    testStarted={true} //{testStarted}*/}
            {/*    testDone={false}//{testDone}*/}
            {/*    index={index333}*/}
            {/*    tag={wordToGuess}*/}
            {/*    seconds={10}//{seconds}*/}
            {/*    renderer={renderer}*/}
            {/*    handleStart={null}*/}
            {/*    //{handleStart}*/}
            {/*></TaskWord>*/}

            <section className="task">
                <div className="task__container">
                    <div>
                        <h2 className="title">Задание:</h2>
                        <p className="description">{description}</p>
                    </div>
                    <div className="task__timer">
                        { testStarted && !testDone
                            ?
                            <CountdownCircleTimer
                                key={keyTimer}
                                isPlaying
                                duration={25}
                                onComplete={(n) => restartTimer()}
                                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                                colorsTime={[7, 5, 2, 0]}
                                size={0}
                            >
                                {RenderTime}
                            </CountdownCircleTimer>                            :
                            0
                        }
                    </div>
                </div>
                <div className={styleTaskValue}>
                    { testDone
                        ?
                        <div>Тест завершен</div>
                        :
                        wordToGuess
                    }
                </div>
            </section>

            {/* <Toaster/> */}
            {/* {stoppedGame? renderEnd() :renderTimers()}*/}
                        <div className="container_main container_column container_shadow">
                            <div className="container_value">
                                <HangmanWord
                                    result={isLoser}
                                    guessLetters={guessLetters}
                                    wordToGuess={wordToGuess}
                                />
                            </div>
                            <div className="container_row">
                                <div className="container_keyboard">
                                    <Keyboard
                                        //KEYS={['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']}                                    disabled={isWinner || isLoser}
                                        KEYS={['z', 'a', 'h', 'c', 'u', 'd',  'i', 'j', 'l',  'y', 'm', 'q',  'v']}                                    disabled={isWinner || isLoser}
                                        activeLetter={guessLetters.filter(letter => wordToGuess.includes(letter))}
                                        inactiveLetter={incorrectLetters}
                                        addGuessLetter={addGuessLetter}
                                    />
                                </div>
                                <div className="container_keyboard">
                                    <Keyboard
                                        KEYS={[ 'n', 'b', 'o', 'e', 'g', 'p', 'f', 'k', 'x', 'r', 'w', 't', 's']}
                                        disabled={isWinner || isLoser}
                                        activeLetter={guessLetters.filter(letter => wordToGuess.includes(letter))}
                                        inactiveLetter={incorrectLetters}
                                        addGuessLetter={addGuessLetter}
                                    />
                                </div>
                            </div>
                        </div>
                        
            {/* <Row>
                <Col xs lg="8">
                    <Row>
                        <div className='flex gap-3 text-3xl lg:text-6xl font-bold uppercase text'>
                            {wordToGuess}
                        </div>
                    </Row>
                    <Row>
                        <HangmanWord
                            result={isLoser}
                            guessLetters={guessLetters}
                            wordToGuess={wordToGuess}
                        />
                    </Row>
                    <Row>
                        <div className='flex gap-3 text-3xl lg:text-6xl font-bold uppercase text'>
                            Ошибок: {wrong}
                        </div>
                    </Row>
                </Col>
                <Col xs lg="4">
                    <Keyboard
                        disabled={isWinner || isLoser}
                        activeLetter={guessLetters.filter(letter => wordToGuess.includes(letter))}
                        inactiveLetter={incorrectLetters}
                        addGuessLetter={addGuessLetter}
                    />
                </Col>
                <Col xs lg="3">
                    <KeyboardDigit
                        disabled={isWinner || isLoser}
                        activeLetter={guessLetters.filter(letter => wordToGuess.includes(letter))}
                        inactiveLetter={incorrectLetters}
                        addGuessLetter={addGuessLetter}
                    />
                </Col>
            </Row> */}
        
            <Result 
                correctValue={'--'}//{correct}
                errorValue={wrong}

            ></Result>
            <div>
                <CountdownCircleTimer
                    key={keyTimerFull}
                    isPlaying
                    duration={60*30}
                    colors={['#589FFC', '#589FFC', '#f1de07', '#A30000']}
                    colorsTime={[1000, 1000, 120, 60]}
                    size={70}
                    strokeWidth={4}
                    onComplete={(_) => stopGame()}>
                    {RenderTimeFull}
                </CountdownCircleTimer>

            </div>
        </div>
        


        // <div className='bg-[radial-gradient(ellipse_at_bottom_left,_var(--tw-gradient-stops))] from-blue-100 via-indigo-100 to-purple-200 h-screen'>
        //   <div className='font-adlam max-w-7xl flex items-center flex-col gap-8 mx-auto pt-12 grid grid-cols-3 gap-5' >
        //     <Toaster />
        //     {/* I want to know how many times I chose the wrong letter */}
        //     {/*<HangmanDraw numberOfGuess={incorrectLetters.length} />*/}
        //     <HangmanWord
        //       result={isLoser}
        //       guessLetters={guessLetters}
        //       wordToGuess={wordToGuess}
        //     />
        //     <div className=''>
        //       <Keyboard
        //         disabled={isWinner || isLoser}
        //         activeLetter={guessLetters.filter(letter => wordToGuess.includes(letter))}
        //         inactiveLetter={incorrectLetters}
        //         addGuessLetter={addGuessLetter}
        //       />
        //     </div>
        //     <div className=''>
        //       <KeyboardDigit
        //         disabled={isWinner || isLoser}
        //         activeLetter={guessLetters.filter(letter => wordToGuess.includes(letter))}
        //         inactiveLetter={incorrectLetters}
        //         addGuessLetter={addGuessLetter}
        //       />
        //     </div>
        //   </div>
        // </div>


    )
}

export default Test1
